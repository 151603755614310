"use client";

import React from "react";
import { Modal, Button } from "react-bootstrap";



const DonateModal= ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Donate to Morris The Connector CIC</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5>🇬🇧 UK Bank Details</h5>
        <p><strong>Account Name:</strong> Morris The Connector CIC</p>
        <p><strong>Account Number:</strong> 77420849</p>
        <p><strong>Sort Code:</strong> 60-83-71</p>

        <h5>🌍 International Bank Details</h5>
        <p><strong>IBAN:</strong> GB76SRLG60837177420849</p>
        <p><strong>SWIFT/BIC:</strong> SRLGGB2L</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DonateModal;
